import React, { useState } from 'react';

export const InputArticle = ({handleInputChange, defaultValue}) => {
    const [isRaw, setIsRaw] = useState(true);

    const toggleRaw = () => {
        setIsRaw(!isRaw);
    }

    return (
        <div className="mb-3">
            <label htmlFor="article" className="form-label">Article</label>
            <button onClick={toggleRaw}>{isRaw ? 'Show Rendered' : 'Show Source'}</button>
            {isRaw ? 
                <textarea className="form-control" id="article" rows="10" onChange={handleInputChange} defaultValue={defaultValue}></textarea> :
                <div className="form-control" id="article" style={{overflow: 'auto', height: '100%', resize: 'vertical'}} dangerouslySetInnerHTML={{ __html: defaultValue }}></div>
            }
        </div>
    );
}