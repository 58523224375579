export const InputPipeline = ({ handleInputChange }) => {
  return (
    <div className="mb-3">
      <label className="form-label">Brand</label>
      <select className="form-select" aria-label="Default select example" id="brand" onChange={handleInputChange} defaultValue="">
        <option value="">Select a brand</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </select>
    </div>
  );
};