import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { generate } from "../services/azure.service";
import Sidebar from "./sidebar";
import promptTemplates from "../data/promptTemplates";
import { useParams } from "react-router-dom";
import { InputPipeline } from "./inputs/input-pipeline";
import { InputArticle } from "./inputs/input-article";

export const DocumentPage = () => {
  let { id } = useParams();
  const promptTemplate = promptTemplates.find((template) => template.id === id);
  const [result, setResult] = useState("");
  const [inputs, setInputs] = useState(promptTemplate.inputDefaults || {});
  const [isLoading, setIsLoading] = useState(false);
  const [isRaw, setIsRaw] = useState(true);

  const toggleRaw = () => {
    setIsRaw(!isRaw);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(result);
      console.log("Result copied to clipboard");
    } catch (err) {
      console.log("Failed to copy result: ", err);
    }
  };

  const handleGenerate = () => {
    setIsLoading(true);
    let prompt = promptTemplate.prompt;
    Object.keys(inputs).forEach((key) => {
      prompt = prompt.replace(`{${key}}`, inputs[key]);
    });

    generate({ prompt: prompt }).then((response) => {
      if (response.error) {
        setResult(response.error.message); // Access the message property of the error object
        setIsLoading(false);
      } else {
        setResult(response.data);
        setIsLoading(false);
      }
    });
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [id]: value }));
  };

  return (
    <PageLayout>
      {isLoading && <div className="loadingOverlay">Loading...</div>}

      <div className="container-fluid bca-wrapper">
        <div className="row">
          <Sidebar />

          <div className="content two-columns">
            <h1>{promptTemplate.title}</h1>

            {promptTemplate.helpText}<br /><br />

            <div className="row">
              <div className="col-12 col-md-6 d-flex flex-column">
                <div className="input-output flex-fill">
                  <header>Input</header>

                  {promptTemplate.inputs.map((input, index) => {
                    const defaultValue = promptTemplate && promptTemplate.inputDefaults ? promptTemplate.inputDefaults[input] : "";
                    if (input === "pipeline") {
                      return <InputPipeline key={index} handleInputChange={handleInputChange} defaultValue={defaultValue} />;
                    } else if (input === "article") {
                      return <InputArticle key={index} handleInputChange={handleInputChange} defaultValue={defaultValue} />;
                    } else {
                      return (
                        <div key={index} className="mb-3">
                          <label className="form-label">{input}</label>
                          <textarea className="form-control" id={input} rows="10" onChange={handleInputChange} defaultValue={defaultValue}></textarea>
                        </div>
                      );
                    }
                  })}

                  <button type="button" className="btn btn-gradient" onClick={handleGenerate}>
                    Generate
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex flex-column">
                <div className="input-output flex-fill">
                  <header>Result</header>
                  {promptTemplate.outputType === 'html' ? (
                    <>
                      <button onClick={toggleRaw}>{isRaw ? "Show Rendered" : "Show Raw"}</button>
                      {isRaw ? <textarea className="form-control result" style={{ overflow: "auto", height: "100%", resize: "vertical" }} defaultValue={result}></textarea> : <div className="form-control result" style={{ overflow: "auto", height: "100%", resize: "vertical" }} dangerouslySetInnerHTML={{ __html: result }}></div>}
                    </>
                  ) : (
                    <div className="result">{result}</div>
                  )}

                  <footer>
                    <div className="row justify-content-between">
                      <div className="col-12 col-md-6">
                  {promptTemplate.outputType === 'html' ? (
                        <button onClick={copyToClipboard}>Copy HTML</button>
                      ) : (
                        <button onClick={copyToClipboard}>Copy Text</button>
                      )}
                      </div>
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
