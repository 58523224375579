// Sidebar.js
import React from 'react';

const Sidebar = () => {
  return (
    <div className="sidebar-wrapper">
      <div className="tabs">
        <a href="#" className="selected">My History</a>
      </div>
      <ul className="my-history">
        <li><a href="#">Article 1</a></li>
        <li><a href="#">Article 2</a></li>
      </ul>
    </div>
  );
};

export default Sidebar;