import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import Sidebar from "./sidebar";
import Card from "./card";
import promptTemplates from "../data/promptTemplates";
import { useAuth0 } from "@auth0/auth0-react";
import logoLargeNoGMA from "../assets/logo-large-noGMA.png";
import GMA from "../assets/GMA.png";

export const HomePage = ({}) => {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>; 
  }

  console.log(user);

  return (
    <PageLayout>
      <div className="container-fluid gma-wrapper">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <div className="logo-container">
              <div className="logo-wrapper">
                <img src={logoLargeNoGMA} alt="Logo" className="logo-large" />
                <img src={GMA} alt="Logo" className="animated-logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          <div
            className="row justify-content-around button-container"          
          >
            <div className="col-12 col-sm-6 col-md-3">
              <button className="retro-button w-100">New</button>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <button className="retro-button w-100">Load</button>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <button className="retro-button w-100">Settings</button>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <button className="retro-button w-100">Account</button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};