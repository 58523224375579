import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_AZURE_API_URL;



export const generate = async (bodyData) => {
  const config = {
    url: `${apiServerUrl}/Generate?code=mJL4Bj_w9WWsH2coeFcPQlm4FJlIWGFV9VsbGYDOYzhbAzFublQZhw==`,
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: bodyData
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};